<template>
  <div>
    <div class="qkxx_title">
      {{ qktitle }}
    </div>
    <div class="num">
      订单编号：{{ num }}
      <span @click="goback()">返回列表</span>
    </div>
    <div>
      <steps :active="active"></steps>
      <trace :active="traceactive" :trace="trace"></trace>
      <articlecont :article="article"></articlecont>
    </div>
    <div class="detailBottom">
      <span v-if="show">
        <el-upload
          class="upload-demo"
          ref="upload"
          :action="path"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="upsuccess"
          :on-change="beforeUpload"
          :data="{ id: wid }"
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button type="danger" style="margin-right: 10px" slot="trigger"
            >选择附件</el-button
          >
          <el-button type="danger" size="samll" @click="submitUpload()"
            >确定上传</el-button
          >
        </el-upload>
      </span>
    </div>
  </div>
</template>

<script>
import steps from '../components/steps'
import trace from '../components/trace'
import articlecont from '../components/articlecont'
import { checksteps, checktrace, checkarticle } from "../../../API/QKD/detail.js"
export default {
  components: {
    steps,
    trace,
    articlecont,
    wid: ""

  },
  data () {
    return {
      qktitle: "",
      num: 123424,
      active: 2,
      trace: [{ "operation_time": "2020-12-26", "operation": "完成", "reject": "reject" }, { "operation_time": "2020-12-26", "operation": "完成", "reject": "reject" }],
      article: [],
      show: "",
      fileList: [],
      fileName: "", // 获取文件文件名
      traceactive: 0
    }
  },
  methods: {
    //这里是上传文件的方法
    submitUpload () {
      console.log(1111);
      var that = this
      if (that.fileName == '') {
        that.$message.error('未选择文件')
      } else {
        that.$refs.upload.submit()
        setTimeout(function () {
          that.$router.push('/Periodical/passed/?id=6')
        }, 3000)
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    upsuccess (res, file, filelist) {
      if (res.code == 0) {
        this.$message.success("上传成功")
        this.$router.push("/Periodical/passed/?id=6")
      } else {
        this.$message.error("上传失败")
      }
    }, beforeUpload (file, fileList) {
      this.fileName = file.name
      console.log(file, fileList)
    },
    goback () {
      history.back()
    }
    //以上是上传文件的方法
  },
  mounted () {
    this.path = this.URL + "/qikan/UploadNotice"
    let id = this.$route.query.wid
    console.log(this.$route.query.wid);
    this.wid = this.$route.query.wid
    if (this.$route.query.id == 6) {
      this.qktitle = "通过稿件"
      this.show = true
    } else if (this.$route.query.id == 7) {
      this.qktitle = "驳回稿件"
      this.show = false
    }
    checksteps(id).then(res => {

      this.num = res.data.data.contributionnumber
      if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 81 || res.data.data.state == 82) {
        this.active = 2
      } else if (res.data.data.state == 21 || res.data.data.state == 22) {
        this.active = 2
      } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 5 || res.data.data.state == 6) {
        this.active = 2
      } else if (res.data.data.state == 32) {
        this.active = 4
      } else {
        this.active = 4
      }
    })
    checktrace(id).then(res => {

      this.trace = res.data.data
      this.traceactive = this.trace.length - 1
    })
    checkarticle(id).then(res => {
      this.article = res.data.data
    })
  },
}
</script>

<style>
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.detailBottom {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>